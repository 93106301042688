<template>
  <div class="table-badge">
    <span>{{ inputValue }}</span>
    <b-badge v-if="getBadgeValue > 0" variant="light-info" class="badge-glow">
      {{ getBadgeValue }} %
    </b-badge>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue';

export default {
  name: 'TableBadge',

  components: {
    BBadge
  },

  props: {
    inputValue: { type: Number },
    calcValue: { type: Number }
  },

  computed: {
    getBadgeValue() {
      return ((this.inputValue / this.calcValue) * 100).toFixed(0)
    }
  }
}
</script>

<style lang="scss">
.badge-glow {
  margin-left: 5px;
}
</style>