import {ApiServiceAbstract} from "@/services/api.service.abstract"

const qs = require('qs')

export class ReApplicationService extends ApiServiceAbstract {
  constructor(http) {
    super(http);
  }

  getReapplyTaskList = (query) => {
    return this.request(this.http.get, 'statistic/task_list/reapply_task_list/', {
      params: query,
      paramsSerializer: function (params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    });
  }

  getEmployeesReApplications = (query) => {
    return this.request(this.http.get, 'statistic/employee/reapply_applications/', {
      params: query,
      paramsSerializer: function (params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    });
  }

  getEmployeesTaskList = (query) => {
    return this.request(this.http.get, 'statistic/employee/reapply_task_list/', {
      params: query,
      paramsSerializer: function (params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    });
  }
}
