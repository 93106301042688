export default class applicationEmployeesModel {
  constructor(item = {}) {
    this.name = item.name;
    this.all_actions = item.all_actions || 0;
    this.no_pick_up = item.no_pick_up || 0;
    this.call_back = item.call_back || 0;
    this.other = item.other || 0;
    this.canceled = item.canceled || 0;
    this.canceled_no_con = item.canceled_no_con || 0;
    this.will_pay = item.will_pay || 0;
    this.paid = item.paid || 0;
    this.paid_rate = getPercentage(item.paid_rate);
    this.paid_after_other = item.paid_after_other;
  }
}

export class applicationsActionsModel {
  constructor(item = {}) {
    this.name = getColumnName(item.name);
    this.amount = item.amount || 0;
    this.percents = item.name !== 'Total' ? getPercentage(item.percents) : '';
  }
}

export class ReapplyEmployeesModel {
  constructor(item = {}) {
    this.name = item.name;
    this.all_actions = item.all_actions || 0;
    this.no_pick_up = item.no_pick_up || 0;
    this.call_back = item.call_back || 0;
    this.other = item.other || 0;
    this.canceled = item.canceled || 0;
    this.canceled_no_con = item.canceled_no_con || 0;
    this.will_reapply = item.will_reapply || 0;
    this.reapplied = item.reapplied || 0;
    this.unpaid_reapplied = item.unpaid_reapplied || 0;
    this.paid_reapplied = item.paid_reapplied || 0;
    this.paid_reapply_rate = getPercentage(item.paid_reapply_rate);
    this.paid_reapplied_after_other = item.paid_reapplied_after_other;
  }
}

function getPercentage(item) {
  if (item) return `${item.toFixed(2)} %`
  else return '0 %'
}

function getColumnName(itemName) {
  if (itemName === 'Total') return 'Total'

  let names = {
    OPEN: { label: 'Open', value: 'OPEN' },
    NO_PICK_UP: { label: 'Didn\'t pick up', value: 'NO_PICK_UP' },
    WILL_PAY: { label: 'Will pay', value: 'WILL_PAY' },
    CALL_BACK: { label: 'Call back', value: 'CALL_BACK' },
    PAID: { label: 'Paid', value: 'PAID' },
    WILL_REAPPLY: { label: 'Will reapply/pay', value: 'WILL_REAPPLY' },
    REAPPLIED: { label: 'Reapplied', value: 'REAPPLIED' },
    CANCELED: { label: 'Canceled - after contact', value: 'CANCELED' },
    CANCELED_NO_CON: { label: 'Canceled - no contact', value: 'CANCELED_NO_CON' },
    OTHER: { label: 'Other', value: 'OTHER' },
    UNPAID_REAPPLIED: { label: 'Not paid Reapplied', value: 'UNPAID_REAPPLIED' },
  }
  return names[itemName]?.label
}
