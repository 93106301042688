<template>
  <b-table
      :items="getList"
      :busy="loading"
      :fields="fields"
      sortable
      responsive
      :sort-by.sync="sortBy"
      class="main-table"
  >
    <template v-slot:head()="data">
      <span :id="`tBookmark-${data.column}`">{{ data.label }}</span>
      <b-tooltip
          v-if="data.field.tooltip"
          placement="auto"
          triggers="hover"
          :target="`tBookmark-${data.column}`"
          :title="data.field.tooltip"
          :delay="{ show: 150, hide: 100 }"
      />
    </template>
    <template #table-busy>
      <div class="text-center text-primary my-2">
        <b-spinner class="align-middle" />
        <strong class="ml-1">Loading...</strong>
      </div>
    </template>
    <template #cell(no_pick_up)="data">
      <table-badge :input-value="data.item.no_pick_up" :calc-value="data.item.all_actions" />
    </template>
    <template #cell(call_back)="data">
      <table-badge :input-value="data.item.call_back" :calc-value="data.item.all_actions" />
    </template>
    <template #cell(other)="data">
      <table-badge :input-value="data.item.other" :calc-value="data.item.all_actions" />
    </template>
    <template #cell(canceled)="data">
      <table-badge :input-value="data.item.canceled" :calc-value="data.item.all_actions" />
    </template>
    <template #cell(canceled_no_con)="data">
      <table-badge :input-value="data.item.canceled_no_con" :calc-value="data.item.all_actions" />
    </template>
    <template #cell(will_reapply)="data">
      <table-badge :input-value="data.item.will_reapply" :calc-value="data.item.all_actions" />
    </template>
    <template #cell(paid_reapplied)="data">
      <table-badge :input-value="data.item.paid_reapplied" :calc-value="data.item.paid_reapplied" />
    </template>
    <template #cell(reapplied)="data">
      <table-badge :input-value="data.item.reapplied" :calc-value="data.item.all_actions" />
    </template>
    <template v-if="getTotalRow" v-slot:custom-foot>
      <tr>
        <th v-for="footerItem in fields">
          <span>{{ getTotalRow[footerItem.key] || 0 }}</span>
        </th>
      </tr>
    </template>
  </b-table>
</template>

<script>
import {
  BSpinner,
  BTable,
  BTooltip,
} from 'bootstrap-vue';
import TableBadge from '@/components/tables/TableBadge';

export default {
  name: 'ApplicationsTable',

  components: {
    BTable,
    BSpinner,
    BTooltip,
    TableBadge
  },

  props: {
    list: Array,
    fields: Array,
    loading: Boolean
  },

  computed: {
    getList() {
      if (!this.list) return []
      return this.list.filter(item => item.name !== 'Total')
    },

    getTotalRow() {
      if (!this.list) return []
      return this.list.find(item => item.name === 'Total')
    }
  },

  setup(props, ctx) {
    return {
      sortBy: 'name'
    }
  }
}
</script>